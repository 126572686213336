/*SLIDER*/

var SUPERSTRONG = (!SUPERSTRONG ? $({}) : SUPERSTRONG);
(function($){

	var _instance
	SUPERSTRONG.Slider = function (){

		this.current = 0,
		this.currentlink="link",
	    this.breakpointSize = window.getComputedStyle(document.body,':after').getPropertyValue('content'),
		this.multiplier = 1,
		this.$carousel = $('#main-slider'),
		this.$cList = $('#slides'),
		this.$cContainer = $('.slider-warper'),
		this.$cWidth = this.$cContainer.width(),
    	this.cLeft = this.$cList.css("left").replace("px",""),
		this.$li = this.$cList.find('li'),
		this.$liLength = this.$li.size(),
		this.numPages = this.$liLength/this.multiplier,
		this.$prev = $('a.prev'),
		this.$next = $('a.next'),
		this.$selectors = $('div.slides-selector'),
		this.$track = $('div.track'),
		this.$frame = $('div.slider-frame>div.hit-zone'),
		this.maxSlideTime = 5000,
		this.tickInterval = 100,
		this.maxTick = 0,
		this.currentTick = 0,
		this.percentTick = 0,
		this.interval = null,
		this.paused = false;

		$(document).ready(function() {
			this.sw = document.body.clientWidth;
			this.$cWidth = this.$cContainer.width();
			this.buildCarousel();
			this.setSelected(this.current);
			this.updateNavigation();
			this.$frame.on('click',function (e){
				e.preventDefault();
				window.location.href = this.currentlink;

			}.bind(this))
		}.bind(this));

		$(window).resize(function(){ //On Window Resize
			this.sw = document.body.clientWidth;
			this.$cWidth = this.$cContainer.width();
			this.breakpointSize = window.getComputedStyle(document.body,':after').getPropertyValue('content');  /* Conditional CSS http://adactio.com/journal/5429/ */
			this.sizeCarousel();
			this.posCarousel();
			SUPERSTRONG.ImageFitter.fittAll();
		}.bind(this));

		this.maxTick = this.maxSlideTime/this.tickInterval;

		this.interval = setInterval(function() {

			if (this.currentTick >= this.maxTick){

				this.currentTick = 0;
				if (this.current >= this.animLimit){
					this.current = 0;
					this.posCarousel();
				}else {
					this.moveRight();
				}
			}
			if (!this.paused){

				this.currentTick++;
				this.percentTick = this.currentTick/this.maxTick;
				this.$track.animate({'width':this.percentTick*100+"%"},{queue:false},50);

			}


		}.bind(this), this.tickInterval);  // will work with every browser

		this.$prev.click(function(e){ //Previous Button Click
			e.preventDefault();
			this.moveLeft();

		}.bind(this));

		this.$next.click(function(e){ //Next Button Click
			e.preventDefault();
			this.moveRight();
		}.bind(this));

		this.$frame.on('mouseover',function (){

			this.paused = true;
			this.$frame.addClass('hover')
		}.bind(this));

		this.$frame.on('mouseleave',function (){

			this.paused = false;
			this.$frame.removeClass('hover')

		}.bind(this));

		this.$selectors.on('click','a.selector',function (e){

				e.preventDefault();
				var _$elm = $(e.currentTarget);
				if (_$elm && _$elm.data('index')>-1){

					this.posCarousel(_$elm.data('index'));

				};

		}.bind(this))

	 };

	var p = SUPERSTRONG.Slider.prototype;
	p.sizeCarousel = function (){

		this.current = 0;
		this.animLimit = this.$liLength/this.multiplier-1;
		this.$li.outerWidth(this.$cWidth/this.multiplier); //Set panel widths
		if (SUPERSTRONG.ImageFitter){

			SUPERSTRONG.ImageFitter.fittAll();

		}

	};
	p.buildCarousel = function (){

		this.sizeCarousel();

		if(Modernizr.touch) {
		   //this.buildSwipe();
		}

	};
	p.posCarousel = function (to){

		this.currentTick = 0;
		if (to>-1) {this.current = to};
		var pos = -this.current * this.$cWidth;
		this.setSelected(this.current);
		this.updateNavigation();
		this.$cList.addClass('animating').css("left",pos);
		setTimeout(function() {
	      		this.$cList.removeClass('animating');
	      		this.cLeft = this.$cList.css("left").replace("px","");
	    	}.bind(this), 700);  // will work with every browser


	};

	p.moveLeft = function (){

		if(this.current>0) {
			this.current--;
		}

		this.posCarousel();
		this.setSelected(this.current);


	};

	p.moveRight = function (){

		if(this.current<this.animLimit) {
		  this.current++;
		}
		this.posCarousel();
		this.setSelected(this.current);


	};

	p.updateNavigation = function (){

		if (this.animLimit<=0){

			this.$prev.addClass('disable');
			this.$next.addClass('disable');
			clearInterval(this.interval);
			return;

		}

		this.$prev.removeClass('disable');
		this.$next.removeClass('disable');
		if (this.current>= this.animLimit){

			this.$next.addClass('disable');

		}
		if (this.current<= 0){

			this.$next.removeClass('disable');
			this.$prev.addClass('disable');
		}

	};

	p.setSelected = function(index){


		this.$cList.find('li>a').each(function (i,item){

			if (i === index){
				this.currentlink=$(item).attr('href');
			}

		}.bind(this));

		this.$selectors.find('a.selector').each(function (i,item){

			if ($(item).data('index') == index){

				$(item).addClass('active');

			}else {

				$(item).removeClass('active');

			}

		})

	}

})(jQuery);
